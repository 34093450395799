@import 'variables';

.fas{
  display:inline-block;
  font:normal normal normal 14px/1 FontAwesome;
  font-size:inherit;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
html {
  body {
    font-size: $font-size-root;

    .page-title {
      margin-top: 70px !important;
      margin-bottom: 70px !important;
    }

    a {
      color: $white;

      &:hover {
        color: $white !important;
      }
    }
  }

  .nopadding {
    padding: 0 !important;
  }

  .cursor-default {
    cursor: default !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  $spaces: (10, 15, 20, 25, 30, 40, 50, 60);
  $directions: (top, bottom, left, right);

  @each $space in $spaces {
    .p-#{$space} {
      padding: #{$space} !important;
    }

    .m-#{$space} {
      margin: #{$space} !important;
    }

    .px-#{$space} {
      padding-right: #{$space}px !important;
      padding-left: #{$space}px !important;
    }

    .py-#{$space} {
      padding-top: #{$space}px !important;
      padding-bottom: #{$space}px !important;
    }

    .mx-#{$space} {
      margin-right: #{$space}px !important;
      margin-left: #{$space}px !important;
    }

    .my-#{$space} {
      margin-top: #{$space}px !important;
      margin-bottom: #{$space}px !important;
    }

    @each $direction in $directions {
      .m#{str-slice($direction, 0, 1)}-#{$space} {
        margin-#{$direction}: #{$space}px !important;
      }

      .p#{str-slice($direction, 0, 1)}-#{$space} {
        padding-#{$direction}: #{$space}px !important;
      }
    }
  }
}


// TODO: Remove these styles and move to appropriate files
.bg-darker-85 {
  background: rgba(0, 0, 0, 0.95) !important;
}

#kvp-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: $ds365-zindex-overlay;
}

tag-input-form {
  width: 100% !important;
}

.ng2-tag-input {
  border-bottom: 0px !important;
}

ds365-manage-user-table,
ds365-manage-company-table,
ds365-cluster-approval,
ds365-subscription-members,
ds365-package,
ds365-company-info,
ds365-am-report,
ds365-company-support,
ds365-cluster-console ,
ds365-expired-subscription,
ds365-company-users,
ds365-support-company-users,
ds365-invoice,
ds365-sap-invoice,
ds365-workspace-report,
ds365-dashboard-company,
ds365-dashboard-users,
ds365-dashboard-subscriptions,
ds365-extend-subscription,
ds365-solution-approval,
ds365-expired-subscription-new
{
  
  ds365-input {
    >div {
      margin-bottom: 5px !important;
    }

    small,
    label {
      display: none !important;
    }
  }
  ds365-select {
    >div {
      margin-bottom: 5px !important;
    }
  }
  .btn-custom {
    background-color: #43494e;
    border-color: #43494e;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .btn-custom:hover {
    background-color: #43494e;
  }

  .btn-outline-success-custom {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-color: #43494e;
  }
}

ckeditor {
  >.ck-editor__editable_inline {
    max-height: 400px;
    margin-bottom: 20px;
  }
}



ds365-get-started {
  ds365-banner { 
    .position-fixed{
    top:0px !important;
    }
  }
  ds365-stepper > .container {
    min-height: 590px !important;
    background-color: $secondary;
  }
}



ds365-price-wizard {
  ds365-input {

    small,
    label {
      display: none !important;
    }
  }

  ds365-select {
    label {
      display: none !important;
    }
  }

  ds365-date-picker {
    label {
      display: none !important;
    }
  }
}

ds365-workdocs{
  ds365-select {
    label {
      display: none !important;
    }
  }
}

ds365-custom-confirm-box {
  ds365-radio-group>div {
    margin: 1rem 0;
    color: white;
    display: flex;
    justify-content: space-around;

    small {
      display: none !important;
    }
  }

  ds365-text-area {
    label, small, .text-danger {
      display: none !important;
    }
  }

  ds365-date-picker>div,
  ds365-input>div {
    margin-bottom: 1rem !important;

    label
    {
      display: none !important;
    }
  }

  ds365-checkbox>div {
    color: white;
  }
}
ds365-training,
ds365-billing-invoice,
ds365-trend-graphs {
  ds365-date-picker {
    label {
      float: left;
    }
    small {
      display: none !important;
    }
  }
  ds365-date-picker {
    label {
      float: left;
    }
    small {
      display: none !important;
    }
  }
}

ds365-approve-subscription,
ds365-extend-subscription {
  label {
    float: left;
  }

  ds365-radio-group>div {
    margin: 1rem 0;
    color: white;
    display: flex;
    justify-content: space-evenly;

    small {
      display: none !important;
    }
  }

  ds365-text-area {
    small, .text-danger {
      display: none !important;
    }
  }

  ds365-date-picker>div,
  ds365-input>div {
    margin-bottom: 0.5rem !important;

    small {
      display: none !important;
    }
  }
}

ds365-approve-cluster-confirm-box {
  overflow-y: auto;
  label {
    float: left;
  }

  ds365-radio-group>div {
    margin: 1rem 0;
    color: white;
    display: flex;
    justify-content: space-evenly;

    small {
      display: none !important;
    }
  }

  ds365-text-area {
    small, .text-danger {
      display: none !important;
    }
  }

  ds365-date-picker>div,
  ds365-input>div {
    margin-bottom: 1rem !important;

    small {
      display: none !important;
    }
  }

}

ds365-confirm-box {
  label {
    float: left;
  }

  ds365-text-area {
    label, small, .text-danger {
      display: none !important;
    }
  }

  ds365-radio-group>div {
    margin: 1rem 0;
    color: white;
    display: flex;
    justify-content: space-evenly;

    small {
      display: none !important;
    }
  }

  ds365-date-picker>div,
  ds365-input>div {
    margin-bottom: 1rem !important;

    small {
      display: none !important;
    }
  }

}

input.ng2-tag-input__text-input.form-control {
  padding: 6px !important;
  box-shadow: none !important;
  width: 250px !important;
  width: 100% !important;
}

.ng2-tags-container {
  tag {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff !important;
    background-color: $secondary !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#{$danger}' d='M2.17,1.55l-.62.61a.31.31,0,0,0,0,.44h0l6,5.93a.69.69,0,0,0,1,0l6-5.93a.3.3,0,0,0,0-.44h0l-.61-.61a.33.33,0,0,0-.47,0L8.51,6.37a.71.71,0,0,1-1,0L2.63,1.55a.32.32,0,0,0-.46,0M8,4.83,12.35.52a1.79,1.79,0,0,1,2.51,0l.62.62a1.77,1.77,0,0,1,0,2.49h0l-6,5.93a2.13,2.13,0,0,1-3,0l-6-5.93a1.76,1.76,0,0,1,0-2.49h0L1.14.51a1.79,1.79,0,0,1,2.51,0Z'/%3e%3c/svg%3e") no-repeat right 1rem center/18px 10px,
  url("data:image/svg+xml,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cdefs%3e%3cstyle%3e.cls-1{fill:%23be1e2d;}%3c/style%3e%3c/defs%3e%3ctitle%3eArtboard 1%3c/title%3e%3cpath class='cls-1' d='M18.52.78a2.68,2.68,0,0,1,3.77,0l.93.92a2.66,2.66,0,0,1,0,3.74l0,0L16.68,12l6.54,6.55a2.65,2.65,0,0,1,0,3.74l0,0-.93.92a2.68,2.68,0,0,1-3.77,0L12,16.69,5.48,23.22a2.68,2.68,0,0,1-3.77,0l-.93-.92a2.66,2.66,0,0,1,0-3.75h0L7.32,12,.79,5.45a2.65,2.65,0,0,1,0-3.74l0,0L1.71.77a2.68,2.68,0,0,1,3.77,0L12,7.31ZM11.23,9.63,3.94,2.33a.49.49,0,0,0-.69,0l-.93.92a.47.47,0,0,0,0,.66h0l7.3,7.32a1.08,1.08,0,0,1,0,1.54L2.32,20.1a.47.47,0,0,0,0,.65h0l.93.92a.49.49,0,0,0,.69,0l7.29-7.31a1.1,1.1,0,0,1,1.54,0h0l7.29,7.3a.49.49,0,0,0,.69,0l.93-.92a.47.47,0,0,0,0-.66h0l-7.31-7.32a1.08,1.08,0,0,1,0-1.54L21.68,3.9a.46.46,0,0,0,0-.65h0l-.93-.92a.5.5,0,0,0-.69,0L12.77,9.63a1.1,1.1,0,0,1-1.54,0Z'/%3e%3c/svg%3e") no-repeat right calc(0.375em + 0.1875rem) center / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
  background-color: $primary !important;
}
.custom-select.ng-dirty.ng-valid{
  border: 1px solid #ffff;
}
.custom-select.ng-dirty.ng-valid:focus{
  border: 2px solid #476283 !important;
}
.custom-select.ng-invalid:focus{
  border-color: $danger;
}
input:focus{
  border: 2px solid;
}
a.nav-link {
  &:hover {
    >span {
      transform: scale(1.2) !important;
    }
  }
}

ds365-user-typeahead,
ds365-action-dropdown {
  ngb-typeahead-window.dropdown-menu {
    margin-top: 0px !important;
  }

  .dropdown-menu {
    height: 300px !important;
    max-height: 300px !important;
    overflow: auto !important;
  }

  .dropdown-item {
    font-size: 1rem;
    color: $white;
    &.active {
      background-color: transparent;
      color: $danger;
    }

    &:active,
    &:hover {
      background-color: transparent;
      color: $danger !important;
    }
  }
}

.action-dropdown.dropdown-item:hover {
  color: inherit;
}

ds365-multi-select {
  .dropdown-menu {
    margin-top: 0px !important;

    .dropdown-item:active {
      color: unset !important;
    }
  }
}

ds365-image-cropper {
  .cropper-container {
    width: 100%;
  }

  .cropper-modal {
    background-color: white;
  }
}

.modal-header {
  border-bottom: 1px solid $white !important;
}

ds365-alert.inside-iframe {
  .ds365-alert {
    top: 0 !important;
  }
}

ds365-banner.inside-iframe {
  .banner {
    top: 0 !important;
  }
}

.row-height {
  min-height: $ds365-banner-height;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  cursor: default !important;
}

html,
body,
ds365-root {
  min-height: 100% !important;
  height: 100%;
}

.nav-link:hover svg-icon .icon,
.nav-link:hover svg-icon.dark .icon {
  fill: $primary !important;
}

button:disabled,
button[disabled] {
  cursor: default;
}

textarea {
  resize: none !important;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light;
    border-radius: 5px;
  }
}

.grid-divider>[class*="col-"]:nth-child(n + 2):after {
  content: "";
  background-color: #cc0000;
  position: absolute;
  top: 0;
  bottom: 0;

  @media (max-width: 767px) {
    width: 100%;
    height: 0px;
    left: 0;
    top: -4px; // quickfix to center line between columns
  }

  @media (min-width: 768px) {
    width: 1px;
    height: auto;
    left: -1px; // quickfix to hide divider on left side
  }
}

ds365-training {
  ds365-input {
    small {
      display: none !important;
    }
  }

  ds365-select {
    small {
      display: none !important;
    }
  }

  ds365-multi-select {
    small {
      display: none !important;
    }
  }

  ds365-date-picker {
    small {
      display: none !important;
    }
  }
}

ds365-company-support,
ds365-cluster-console,
ds365-expired-subscription,
ds365-user-support,
ds365-list,
ds365-cluster-approval {
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: #202225;
        border-color: #e9ecef #e9ecef #dee2e6;
      }

      .nav-link.active {
        color: maroon;
        background-color: #e9ecef;
      }

      a {
        min-width: 200px;
      }
    }

    .nav-item:nth-child(n+2) {
      margin-left: -8px;
    }

  }
  .btn-custom {
    background-color: #43494e;
    border-color: #43494e;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
  }

  .btn-custom:hover {
    background-color: #43494e;
  }

  .btn-outline-success-custom {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-color: #43494e;
    background-color: $secondary;
  }
}
.ta-r {
  text-align: right;
}
.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.breakdown-list {
  span::before {
    content: '-  ';
  }

  padding: 0.75rem 0 0 1.5rem;
  text-indent: -0.6em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
   -webkit-box-shadow: 0 0 0 30px $input-bg inset !important;
   -webkit-text-fill-color: $white !important;
}

ds365-date-range-picker {
  .fas{
    font-size: 25px !important;
    color:white
  }
  .fas:hover{
    color:#6e777c
  }
  .calendar .table th {
    font-weight: 400;
    color: white !important;
}
  .calendar .table td.selected, 
  .calendar .table td.selected.in-selected-range {
    color: white !important;
    background-color: #003d79;
    border-radius: 5px;
  }
  .calendar .table td.in-selected-range{
    color: $primary !important;
  }
  .calendar .table td{
    color:white;
  }
  .calendar .table td:not(.disabled):not(.selected):hover {
    background-color: white !important;
    color: $primary !important;
  }
  .calendar .table td.different-month.disabled{
    background-color: transparent !important;
    color: #666 !important;
    cursor: not-allowed !important;
    font-weight: 400 !important;
  }

  .btn-outline-secondary{
    color: white !important;
    border-color: white !important;
  }
  .calendar{
    color: #fff !important;
  }



  .dateRangePicker-wrapper .dateRangePicker{
    background: $primary !important;
    min-width: 720px;
  }

  @media screen and (min-width: 576px){
  .dateRangePicker-wrapper .dateRangePicker {
      min-width: 720px;
    }
  }
}

ds365-date-picker {

  dp-date-picker {
    width: 100%;
    .dp-input-container {
      .dp-picker-input {
        width: 100%;
        height: calc(1.5em + 0.4rem + 2px);
        padding: 0.2rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #fff;
        background-color: $primary;
        background-clip: padding-box;
        border: 1px solid #43494e;
        border-radius: 0.25rem;
      }
    }
  }

  dp-calendar-nav .dp-nav-header {
    font-size: large;
  }

  dp-day-calendar.dp-material {
    .dp-calendar-weekday,
    .dp-calendar-day {
      width: 35px;
    }
  }

 
  .dp-popup {
    background: $primary;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    min-width: 10rem;
    font-size: 1rem;
    color: #fff;
    text-align: left;
  }

  dp-month-calendar.dp-material .dp-calendar-wrapper,
  dp-day-calendar.dp-material .dp-calendar-wrapper {
    border: none;
  }

  dp-calendar-nav.dp-material .dp-calendar-nav-container {
    border: none;
    height: 2rem;
    border-bottom: 1px solid rgba(0,0,0,.125);
  }

  dp-month-calendar .dp-month-calendar-container,
  dp-day-calendar .dp-day-calendar-container {
    background: $primary;
  }

  dp-month-calendar.dp-material .dp-calendar-month,
  dp-day-calendar.dp-material .dp-calendar-day {
    background: $primary;
    border: none;
    box-sizing: border-box;
    outline: none;
    color: white;
  }

  dp-month-calendar.dp-material .dp-selected,
  dp-month-calendar.dp-material .dp-calendar-month:hover,
  dp-day-calendar.dp-material .dp-selected,
  dp-day-calendar.dp-material .dp-calendar-day:hover {
    background: $secondary;
  }

  dp-month-calendar.dp-material .dp-current-month,
  dp-day-calendar.dp-material .dp-current-day {
    border: none;
  }

  dp-calendar-nav.dp-material .dp-calendar-nav-left,
  dp-calendar-nav.dp-material .dp-calendar-nav-right,
  dp-calendar-nav.dp-material .dp-calendar-secondary-nav-left,
  dp-calendar-nav.dp-material .dp-calendar-secondary-nav-right,
  dp-calendar-nav.dp-material .dp-nav-header-btn {
    background-color: unset;
    color: white;
  }

  button:disabled, button[disabled] {
    color: #6c757d !important;
  }


}
.modal-backdrop {
  z-index: 1050 !important;
}
